import type { EndpointDefinitions } from '@/features/api';
import type { CompanyResponse } from '@/features/companies';
import { API_BASE } from '@/shared';
import type {
    CreateCampaignRequest,
    CreateEditOrderPromotionRequest,
    CreateOrderRequest,
    EditCampaignRequest,
    FilterOrdersRequest,
} from './campaignsApiRequestModels';
import type { CampaignResponse, CampaignsConfigResponse, OrderPromotionResponse, SimpleCampaignResponse } from './campaignsApiResponseModels';

export type CampaignsEndpointDefinitions = {
    GET_CONFIG: {
        request: never;
        response: CampaignsConfigResponse;
        kind: 'query';
    };
    LOCATE_COMPANY_ID: {
        request: never;
        response: number;
        kind: 'query';
    };
    LOCATE_COMPANY: {
        request: never;
        response: CompanyResponse;
        kind: 'query';
    };
    LIST_CAMPAIGNS: {
        request: FilterOrdersRequest;
        response: SimpleCampaignResponse[];
        kind: 'query';
    };
    GET_CAMPAIGN: {
        request: never;
        response: CampaignResponse;
        kind: 'query';
    };
    CREATE_CAMPAIGN: {
        request: CreateCampaignRequest;
        response: string;
        kind: 'command';
    };
    EDIT_CAMPAIGN: {
        request: EditCampaignRequest;
        response: string;
        kind: 'command';
    };
    CREATE_ORDER: {
        request: CreateOrderRequest;
        response: string;
        kind: 'command';
    };
    GET_PROMOTIONS: {
        request: never;
        response: OrderPromotionResponse[];
        kind: 'query';
    }
    CREATE_PROMOTION: {
        request: CreateEditOrderPromotionRequest;
        response: string;
        kind: 'command';
    },
    UPDATE_PROMOTION: {
        request: CreateEditOrderPromotionRequest;
        response: string;
        kind: 'command';
    },
    DELETE_PROMOTION: {
        request: string;
        response: string;
        kind: 'command';
    }
};

export const CAMPAIGNS_ENDPOINTS = {
    GET_CONFIG: {
        path: `${API_BASE}/campaigns/config`,
        method: 'GET',
        kind: 'query',
    },
    LOCATE_COMPANY_ID: {
        path: `${API_BASE}/campaigns/{campaignId}/locate-company-id`,
        method: 'GET',
        kind: 'query',
    },
    LOCATE_COMPANY: {
        path: `${API_BASE}/campaigns/{campaignId}/locate-company`,
        method: 'GET',
        kind: 'query',
    },
    LIST_CAMPAIGNS: {
        path: `${API_BASE}/campaigns/list`,
        method: 'POST',
        kind: 'query',
    },
    GET_CAMPAIGN: {
        path: `${API_BASE}/campaigns/{campaignId}`,
        method: 'GET',
        kind: 'query',
    },
    CREATE_CAMPAIGN: {
        path: `${API_BASE}/{companyId}/campaigns/create`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Campaign created successfully',
    },
    EDIT_CAMPAIGN: {
        path: `${API_BASE}/{companyId}/campaigns/{campaignId}`,
        method: 'PUT',
        kind: 'command',
        successMessage: 'Campaign saved',
    },
    CREATE_ORDER: {
        path: `${API_BASE}/{companyId}/campaigns/{campaignId}/create-order`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Order created successfully',
    },
    GET_PROMOTIONS: {
        path: `${API_BASE}/{orderId}/promotions`,
        method: 'GET',
        kind: 'query',  
    },
    CREATE_PROMOTION: {
        path: `${API_BASE}/{orderId}/promotions`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Promotion created successfully',
    },
    UPDATE_PROMOTION: {
        path: `${API_BASE}/{orderId}/promotion/{promotionId}`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Promotion updated',
    },
    DELETE_PROMOTION: {
        path: `${API_BASE}/{orderId}/promotion/{promotionId}`,
        method: 'DELETE',
        kind: 'command',
        successMessage: 'Promotion deleted',
    },
} as const satisfies EndpointDefinitions<CampaignsEndpointDefinitions>;
